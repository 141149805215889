var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"com.zeeman.site@1e4e699"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const sentrySettings = {
	dsn: document.querySelector('meta[name="sentry:dsn"]')?.getAttribute("content") ?? undefined,
	environment:
		document.querySelector('meta[name="sentry:environment"]')?.getAttribute("content") ??
		"development",
};

Sentry.init({
	dsn: sentrySettings.dsn,
	environment: sentrySettings.environment,
	release: process.env.NEXT_PUBLIC_RELEASE,
	integrations: [Sentry.replayIntegration()],
	tracesSampleRate: 0,
	beforeSend: (event, hint) => {
		const errorMessage = event.message || hint.originalException?.toString() || "";

		const groupConfig: { errorRegex: RegExp; message: string; fingerprint: string }[] = [
			{
				errorRegex: /MISSING_MESSAGE/gi,
				message: `Missing translation: ${errorMessage}`,
				fingerprint: "missing-translation",
			},
			{
				errorRegex: /ConcurrentModification/gi,
				message: `Concurrent modification error: ${errorMessage}`,
				fingerprint: "concurrent-modification",
			},
		];

		for (const { errorRegex, message, fingerprint } of groupConfig) {
			if (errorRegex.test(errorMessage)) {
				event.message = message;
				event.fingerprint = [fingerprint];
				break;
			}
		}

		return event;
	},
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
